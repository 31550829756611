import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";

const SocialLinks = () => {
  const isMobile = window.innerWidth <= 768; // Define a breakpoint for mobile devices

  return (
    <div className="flex flex-col top-[35%] left-0 fixed">
      <ul>
        {/* Twitter */}
        <li className={`group w-10 h-10 bg-blue-900 rounded-md hover:rounded-xl transition-all ${isMobile ? 'w-6 h-6' : ''}`}>
          <a href="https://twitter.com/RamroImports" className="flex justify-center items-center w-full h-full text-white" target="_blank" rel="noopener noreferrer">
            <FaTwitter size={isMobile ? 9 : 20} className="icon transition-all transform translate-x-0 group-hover:translate-x-[-50%]" />
          </a>
        </li>

        {/* Instagram */}
        <li className={`group w-10 h-10 bg-blue-900 rounded-md hover:rounded-xl transition-all mt-1 ${isMobile ? 'w-6 h-6' : ''}`}>
          <a href="https://www.instagram.com/ramrobv1/" className="flex justify-center items-center w-full h-full text-white" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={isMobile ? 9 : 20} className="icon transition-all transform translate-x-0 group-hover:translate-x-[-50%]" />
          </a>
        </li>

        {/* Facebook */}
        <li className={`group w-10 h-10 bg-blue-900 rounded-md hover:rounded-xl transition-all mt-1 ${isMobile ? 'w-6 h-6' : ''}`}>
          <a href="https://www.facebook.com/ramro.imports.bv/" className="flex justify-center items-center w-full h-full text-white" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={isMobile ? 9 : 20} className="icon transition-all transform translate-x-0 group-hover:translate-x-[-50%]" />
          </a>
        </li>

        {/* LinkedIn */}
        <li className={`group w-10 h-10 bg-blue-900 rounded-md hover:rounded-xl transition-all mt-1 ${isMobile ? 'w-6 h-6' : ''}`}>
          <a href="https://linkedin.com/company/ramro-imports-b-v" className="flex justify-center items-center w-full h-full text-white" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={isMobile ? 9 : 20} className="icon transition-all transform translate-x-0 group-hover:translate-x-[-50%]" />
          </a>
        </li>

        {/* WhatsApp */}
        <li className={`group w-10 h-10 bg-blue-900 rounded-md hover:rounded-xl transition-all mt-1 ${isMobile ? 'w-6 h-6' : ''}`}>
          <a
            href="https://wa.me/31617844159?text=Hello! I need assistance with your services."
            className="flex justify-center items-center w-full h-full text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={isMobile ? 9 : 20} className="icon transition-all transform translate-x-0 group-hover:translate-x-[-50%]" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialLinks;
