import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  Navbar,
  About,
  MyCarousel,
  VideoCarousel,
  Contact,
  Hero,
  
  Ourmission,
  ProductGallery,
  StarsCanvas,
  CrispChat,
  SocialLinks,
  Footer,
 
  VerifyEmail,
} from './components';

const App = () => {
  return (
    <Router>
      <div className='relative z-0 bg-primary'>
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar />
          <Hero />
        </div>
        <About />
        <MyCarousel />
        <VideoCarousel/>
        <Ourmission />
        <ProductGallery />
        <CrispChat/>
        <SocialLinks/>
        <VerifyEmail/>
        
        <div className='relative z-0'>
          <Contact />
          <Footer />
          <StarsCanvas />
        </div>
      </div>
      
      
    </Router>
  );
};

export default App;
