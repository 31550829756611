import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignInAlt, faTimes } from "@fortawesome/free-solid-svg-icons"; // Changed icon
import Modal from "react-modal";

import { styles } from "../styles";
import { navLinks } from "../constants";
import { menu, close } from "../assets";
import myLogo from "../assets/logos.png"; // Make sure the path is correct
import SignUp from "./SignUp";  // Make sure the path is correct
import Login from "./LogIn";    // Make sure the path is correct


Modal.setAppElement("#root");

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false); // Set to false initially

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleForm = () => {
    setShowSignUp(!showSignUp);
  };

  return (
    <nav
      className={`${
        styles.paddingX
      } w-full flex items-center py-5 fixed top-0 z-20 ${
        scrolled ? "bg-primary" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img src={myLogo} alt="logo" className="w-40 h-40 object-contain" />
        </Link>

        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={() => setActive(nav.title)}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>

        <div className="hidden sm:flex flex-row gap-4">
          <div
            className="text-secondary hover:text-white cursor-pointer"
            onClick={openModal}
          >
            <FontAwesomeIcon icon={faUser} size="lg" /> {/* Changed icon */}
          </div>
        </div>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                    setActive(nav.title);
                  }}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
              <li className="font-poppins font-medium cursor-pointer text-[16px] text-secondary hover:text-white">
                <div onClick={openModal}>
                  <FontAwesomeIcon icon={faUser} size="lg" /> {/* Changed icon */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Sign Up / Sign In"
        className="fixed inset-0 flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-light-blue rounded-lg shadow-lg w-11/12 max-w-lg m-auto max-h-screen overflow-y-auto">
          <button onClick={closeModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <div className="bg-primary w-full p-4 flex justify-center rounded-t-lg">
            <img src={myLogo} alt="logo" className="w-32 h-32 object-contain" />
          </div>
          <div className="p-4">
            {showSignUp ? (
              <div>
                <SignUp />
                <button onClick={toggleForm} className="block w-full bg-blue-500 text-white p-2 rounded mt-4">
                  Switch to Sign In
                </button>
              </div>
            ) : (
              <div>
                <Login />
                <button onClick={toggleForm} className="block w-full bg-blue-500 text-white p-2 rounded mt-4">
                  Switch to Sign Up
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default Navbar;
