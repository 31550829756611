import React, { useState } from 'react';
import ProductCard from './ProductCard';
import { slideIn } from "../utils/motion";
import { motion } from "framer-motion";

const productData = [
    {
    category: 'Tassen',
    subcategory: 'Bedrukte Tassen',
    image: 'src/assets/Bags/bgs.jpg',
    description: 'Coole bedrukte Canvas tassen voor iedereen',
    catalogUrl: '/Catalogs/Canvastassen.pdf', 
    catalogUrlEnglish: '/Catalogs/Canvastassen_en.pdf',
    catalogUrlGerman: '/Catalogs/Canvastassen_de.pdf',
  },
  {
    category: 'Tassen',
    subcategory: 'Vrijetijdstassen',
    image: 'src/assets/Bags/cb.jpg',
    description: 'Coole Vrijetijdstassen voor iedereen',
    catalogUrl: '/Catalogs/Vrijetijdstassen.pdf', 
    catalogUrlEnglish: '/Catalogs/Vrijetijdstassen_en.pdf',
    catalogUrlGerman: '/Catalogs/Vrijetijdstassen_de.pdf',
  },
  {
    category: 'Tassen',
    subcategory: 'Boodschappentassen',
    image: 'src/assets/Bags/sb.jpg',
    description: 'Coole boodschappentassen voor iedereen',
    catalogUrl: '/Catalogs/Shoppingbags.pdf',
    catalogUrlEnglish: '/Catalogs/Shoppingbags_en.pdf',
    catalogUrlGerman: '/Catalogs/Shoppingbags_de.pdf',
  },
  {
    category: 'Tassen',
    subcategory: 'Coole Heuptassen',
    image: 'src/assets/Bags/wb.jpg',
    description: 'Coole heuptassen voor iedereen',
    catalogUrl: '/Catalogs/Waistbags.pdf',
    catalogUrlEnglish: '/Catalogs/Waistbags_en.pdf',
    catalogUrlGerman: '/Catalogs/Waistbags_de.pdf',
  },
  {
    category: 'Kerstmis',
    subcategory: 'Kerstversieringen',
    image: 'src/assets/Christmas/ch.jpg',
    description: '100% Fair Trade Kerstversieringen voor in de boom en thuis',
    catalogUrl: '/Catalogs/CO.pdf',
    catalogUrlEnglish: '/Catalogs/CO_en.pdf',
    catalogUrlGerman: '/Catalogs/CO_de.pdf',
  },
  {
    category: 'Kerstmis',
    subcategory: 'Kerststallen',
    image: 'src/assets/Christmas/c.jpg',
    description: '100% Fair Trade Kerststallen',
    catalogUrl: '/Catalogs/ns.pdf',
    catalogUrlEnglish: '/Catalogs/ns_en.pdf',
    catalogUrlGerman: '/Catalogs/ns_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Hennep T-shirts',
    image: 'src/assets/Hemp/ts.jpg',
    description: 'T-Shirts van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Hennep Hoodies',
    image: 'src/assets/Hemp/hp.jpg',
    description: 'Hoodies van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Hennep Pakken',
    image: 'src/assets/Hemp/suit.jpg',
    description: 'Pakken van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Hennep Spijkerbroeken',
    image: 'src/assets/Hemp/hp.jpg',
    description: 'Spijkerbroeken van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Hemp Broeken',
    image: 'src/assets/Hemp/ht.jpg',
    description: 'Broeken van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Hennep',
    subcategory: 'Petten en hoeden',
    image: 'src/assets/Hemp/caps.jpg',
    description: 'Petten en hoeden van hennep',
    catalogUrl: '/Catalogs/Hempproducts.pdf',
    catalogUrlEnglish: '/Catalogs/Hempproducts_en.pdf',
    catalogUrlGerman: '/Catalogs/Hempproducts_de.pdf',
  },
  {
    category: 'Huishoudelijk texiel',
    subcategory: 'Onderzetters',
    image: 'src/assets/Textiles/coaster.png',
    description: '100% Fair Trade Onderzetters',
    catalogUrl: '/Catalogs/Coasters.pdf',
    catalogUrlEnglish: '/Catalogs/Coasters_en.pdf',
    catalogUrlGerman: '/Catalogs/Coasters_de.pdf',
  },
  {
    category: 'Huishoudelijk texiel',
    subcategory: 'Kussens',
    image: 'src/assets/Textiles/cush.png',
    description: '100% Fair Trade Kussens',
    catalogUrl: '/Catalogs/Cushions.pdf',
    catalogUrlEnglish: '/Catalogs/Cushions_en.pdf',
    catalogUrlGerman: '/Catalogs/Cushions_de.pdf',
  },
  {
    category: 'Huishoudelijk texiel',
    subcategory: 'Placemats',
    image: 'src/assets/Textiles/pm.png',
    description: '100% Fair Trade Placemats',
    catalogUrl: '/Catalogs/Placemats.pdf',
    catalogUrlEnglish: '/Catalogs/Placemats_en.pdf',
    catalogUrlGerman: '/Catalogs/Placemats_de.pdf',
  },
  {
    category: 'Huishoudelijk texiel',
    subcategory: 'Theedoeken',
    image: 'src/assets/Textiles/pla.png',
    description: '100% Fair Trade Theedoeken',
    catalogUrl: '/Catalogs/Teatowels.pdf',
    catalogUrlEnglish: '/Catalogs/Teatowels_en.pdf',
    catalogUrlGerman: '/Catalogs/Teatowels_de.pdf',
  },
  {
    category: 'Juwelen',
    subcategory: 'Papieren Juwelen',
    image: 'src/assets/Jewelry/pap.png',
    description: '100% Fair Trade & Handgemaakte Papieren Juwelen',
    catalogUrl: '/Catalogs/Paperjewellery.pdf',
    catalogUrlEnglish: '/Catalogs/Paperjewellery_en.pdf',
    catalogUrlGerman: '/Catalogs/Paperjewellery_de.pdf',
  },
  {
    category: 'Juwelen',
    subcategory: 'Metalen Juwelen',
    image: 'src/assets/Jewelry/jw.jpg',
    description: '100% Fair Trade & Handgemaakte Metalen Juwelen',
    catalogUrl: '/Catalogs/Metalenjuwelen.pdf',
    catalogUrlEnglish: '/Catalogs/Metalenjuwelen_en.pdf',
    catalogUrlGerman: '/Catalogs/Metalenjuwelen_de.pdf',
  },
  {
    category: 'Wellness en Bio',
    subcategory: 'Palmborden',
    image: 'src/assets/Leaf/p.jpeg',
    description: '100% milieuvriendelijke en natuurlijke Palmborden',
    catalogUrl: '/Catalogs/Palmborden.pdf', 
    catalogUrlEnglish: '/Catalogs/Biozepen_en.pdf',
    catalogUrlGerman: '/Catalogs/Biozepen_de.pdf',
  },
  {
    category: 'Wellness en Bio',
    subcategory: 'Singing Bowls',
    image: 'src/assets/Singing/bowl.jpeg',
    description: 'Klankschalen met een natuurlijke ontspannende vibratie',
    catalogUrl: '/Catalogs/SingingBowls.pdf', 
    catalogUrlEnglish: '/Catalogs/Biotandenborstels_en.pdf',
    catalogUrlGerman: '/Catalogs/Biotandenborstels_de.pdf',
  },
  {
    category: 'Wellness en Bio',
    subcategory: 'Wierooks von Nepal',
    image: 'src/assets/Incense/1.jpg',
    description: 'Geuren die kracht geven',
    catalogUrl: '/Catalogs/Incense.pdf', 
    catalogUrlEnglish: '/Catalogs/Canvastassen_en.pdf',
    catalogUrlGerman: '/Catalogs/Canvastassen_de.pdf',
  },
 
  {
    category: 'Wol',
    subcategory: 'Wollen jassen',
    image: 'src/assets/Woolen/j.jpg',
    description: 'Warme jassen, 100% wol',
    catalogUrl: '/Catalogs/Wollenjassen.pdf', 
    catalogUrlEnglish: '/Catalogs/Wooltoys_en.pdf',
    catalogUrlGerman: '/Catalogs/Wooltoys_de.pdf',
  },
  {
    category: 'Wol',
    subcategory: 'Wollen mutsen',
    image: 'src/assets/Woolen/wc.jpg',
    description: 'Warme mutsen, 100% wol',
    catalogUrl: '/Catalogs/Wollenmutsen.pdf', 
    catalogUrlEnglish: '/Catalogs/Woolflowers_en.pdf',
    catalogUrlGerman: '/Catalogs/Woolflowers_de.pdf',
  },
  {
    category: 'Wol',
    subcategory: 'Wollen handschoenen',
    image: 'src/assets/Woolen/wg.jpg',
    description: 'Warme handschoenen, 100% wol',
    catalogUrl: '/Catalogs/Handschoenen.pdf', 
    catalogUrlEnglish: '/Catalogs/Wooljewellery_en.pdf',
    catalogUrlGerman: '/Catalogs/Wooljewellery_de.pdf',
  },
];

const ProductGallery = () => {
  const [activeFilter, setActiveFilter] = useState('Alles');
  const filters = ['Alles', 'Tassen', 'Kerstmis', 'Hennep', 'Huishoudelijk texiel', 'Juwelen', 'Wellness en Bio', 'Wol'];

  const filteredProducts =
    activeFilter === 'Alles'
      ? productData
      : productData.filter((product) => product.category === activeFilter);

  const handleFilterSelection = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <motion.div
      id="products-gallery"
      variants={slideIn("left", "tween", 0.1, 1)}
      initial="hidden"
      animate="show"
      exit="hidden"
      className="px-8 py-8 flex flex-col items-center"
    >
      <h1 className="text-3xl font-bold mb-4">Onze producten</h1>

      <div className="mb-4 sm:hidden">
        <select
          className="block appearance-none w-full bg-navyblue text-lightblue-400 border border-lightblue-400 hover:border-lightblue-500 px-6 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          value={activeFilter}
          onChange={(e) => handleFilterSelection(e.target.value)}
          style={{ backgroundColor: '#0078BD' }}
        >
          {filters.map((filter, index) => (
            <option key={index} value={filter}>{filter}</option>
          ))}
        </select>
      </div>

      <div className="hidden sm:flex justify-center mb-4">
        {filters.map((filter, index) => (
          <button
            key={index}
            onClick={() => handleFilterSelection(filter)}
            className={`mr-3 px-8 py-2 rounded-md ${activeFilter === filter ? 'bg-blue-500 text-white' : 'bg-blue-900 text-black-700'}`}
          >
            {filter}
          </button>
        ))}
      </div>

      <motion.div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredProducts.map((product, index) => (
          <motion.div
            key={index}
            variants={slideIn("left", "tween", 0.1, 1)}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            <ProductCard product={product} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default ProductGallery;
